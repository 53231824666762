<template>
  <component :is="invoiceData === undefined ? 'div' : 'div'">

    <!-- Alert: No item found -->
    <b-alert
      variant="primary"
      v-if="!invoiceData"
    >
      <div class="alert-body">
        No invoice selected to edit. Check
        <b-link
          class="alert-link"
          :to="{ name: 'patient-list'}"
        >
          No invoice
        </b-link>
        for more invoices.
      </div>
    </b-alert>

    <!-- Show Info About Appointment -->
    <b-card
      v-if="invoiceData && invoiceData.Patient"
    >
      <!-- Header: Basic Info -->
      <div class="d-flex mt-1 mb-2">
        <feather-icon
          icon="ClipboardIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Appointment Info
        </h4>
      </div>
      <b-row>
        <b-col
          cols="12"
          xl="5"
        >
          <table class="mt-1 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75 text-success"
                />
                <span class="font-weight-bold">Doctor Name</span>
              </th>
              <td class="pb-50">
                <router-link
                  v-if="invoiceData && invoiceData.Doctor"
                  :to="{ name: 'doctor-view', params: { id: invoiceData.Doctor.Id } }"
                  class="font-weight-bold d-block text-nowrap"
                  target="_blank"
                >
                  {{ invoiceData.Doctor.Name }}
                </router-link>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75 text-danger"
                />
                <span class="font-weight-bold">Patient Name</span>
              </th>
              <td class="pb-50">
                <router-link
                 v-if="invoiceData && invoiceData.Patient"
                 :to="{ name: 'patient-view', params: { id: invoiceData.Patient.Id } }"
                 class="font-weight-bold d-block text-nowrap"
                 target="_blank"
                >
                  {{ invoiceData.Patient.Name }}
                </router-link>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="FilePlusIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Visit</span>
              </th>
              <td class="pb-50">
              <router-link
                 v-if="invoiceData && invoiceData.Appointment"
                 :to="{ name: 'visit-operation', query: { appointment_id: invoiceData.Appointment.Id } }"
                 class="font-weight-bold d-block text-nowrap"
                 target="_blank"
                >
                 View Visit Details
                </router-link>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Appointment Day</span>
              </th>
              <td
                v-if="invoiceData && invoiceData.Appointment"
                class="pb-50"
              >
                 {{ invoiceData.Appointment.Day }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="ClockIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Appointment From</span>
              </th>
              <td
                v-if="invoiceData && invoiceData.Appointment"
                class="pb-50"
              >
                 {{ invoiceData.Appointment.From }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="ClockIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Appointment To</span>
              </th>
              <td
                v-if="invoiceData && invoiceData.Appointment"
                class="pb-50"
              >
                 {{ invoiceData.Appointment.To }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      v-if="invoiceData && invoiceData.Patient"
    >

     <!-- Invoice Info: Input Fields -->
     <validation-observer
       #default="{ handleSubmit }"
       ref="refFormObserver"
     >
       <!-- Form -->
       <b-form
         class="p-1"
         v-if="invoiceData"
         @submit.prevent="handleSubmit(onUpdateInvoiceData)"
         @reset.prevent="resetForm"
       >
         <!-- Header: Basic Info -->
         <div class="d-flex mt-1 mb-2">
           <feather-icon
             icon="ClipboardIcon"
             size="19"
           />
           <h4 class="mb-0 ml-50">
             Invoice Info
           </h4>
         </div>

         <!-- Field: Cost -->
         <b-row>
           <b-col
             cols="12"
             md="12"
           >
             <validation-provider
               #default="validationContext"
               name="Cost"
               rules="required"
             >
               <b-form-group
                 label="Cost (EGP)"
                 label-for="cost"
               >
                 <b-form-input
                   id="cost"
                   v-model="invoiceData.Cost"
                   :state="getValidationState(validationContext)"
                   trim
                   type="number"
                 />
                 <b-form-invalid-feedback>
                   {{ validationContext.errors[0] }}
                 </b-form-invalid-feedback>
               </b-form-group>
             </validation-provider>
           </b-col>
         </b-row>

         <!-- Field: Details -->
         <b-row>
           <b-col
             cols="12"
             md="12"
           >
             <validation-provider
               #default="validationContext"
               name="Details"
             >
               <b-form-group
                 label="Details"
                 label-for="details"
               >
                 <b-form-textarea
                   id="details"
                   v-model="invoiceData.Details"
                   :state="getValidationState(validationContext)"
                   trim
                   placeholder=""
                   rows="3"
                 />
                 <b-form-invalid-feedback>
                   {{ validationContext.errors[0] }}
                 </b-form-invalid-feedback>
               </b-form-group>
             </validation-provider>
           </b-col>
         </b-row>

         <!-- Action Buttons -->
         <div class="d-flex mt-3">
           <b-button
             variant="primary"
             class="mr-1"
             :block="$store.getters['app/currentBreakPoint'] === 'xs'"
             type="submit"
           >
              Save Changes
           </b-button>
           <b-button
             variant="outline-secondary"
             type="reset"
             :block="$store.getters['app/currentBreakPoint'] === 'xs'"
             @click="$router.push({ name: 'visit-list' })"
           >
             Cancel
           </b-button>
         </div>
       </b-form>
     </validation-observer>
    </b-card>

  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BFormGroup, BFormInput, BForm, BFormTextarea, BFormInvalidFeedback, BButton, BRow, BCol, BTable, BContainer,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import InvoiceStoreModule from '@/store/main/InvoiceStoreModule'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
// Validation
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BFormTextarea,
    BButton,
    BRow,
    BCol,
    BTable,
    BContainer,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup() {
    const INVOICE_STORE_MODULE_NAME = 'invoice-store'
    // Register module
    if (!store.hasModule(INVOICE_STORE_MODULE_NAME)) store.registerModule(INVOICE_STORE_MODULE_NAME, InvoiceStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_STORE_MODULE_NAME)
    })

    //----------------------------------------------------------------------------------------------------------------------------
    const toast = useToast()
    const invoiceData = ref(null)

    if (router.currentRoute.query.appointment_id === undefined || router.currentRoute.query.appointment_id === null) {
      invoiceData.value = undefined
      return {
        invoiceData,
      }
    }

    store.dispatch('invoice-store/getInvoiceByAppointmentId', { id: router.currentRoute.query.appointment_id })
      .then(response => {
        invoiceData.value = response.data.Data
      })
      .catch(error => {
        if (error.response.status === 404) {
          invoiceData.value = undefined
        }
      })

    const onUpdateInvoiceData = () => {
      const updatedInvoiceDataObject = {}
      updatedInvoiceDataObject.AppointmentId = invoiceData.value.Appointment.Id
      updatedInvoiceDataObject.InvoiceId = invoiceData.value.Id
      updatedInvoiceDataObject.Cost = invoiceData.value.Cost
      updatedInvoiceDataObject.Details = invoiceData.value.Details

      store.dispatch('invoice-store/updateInvoice', updatedInvoiceDataObject)
        .then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Operation Done',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'You have successfully update invoice!',
            },
          })
          router.push({ name: 'visit-list' })
          // this.$router.go(-1)
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Operation Failed',
              icon: 'XIcon',
              variant: 'danger',
              text: 'Error happened when updating invoice!',
            },
          })
        })
    }

    const resetinvoiceData = () => {
      // eslint-disable-next-line no-param-reassign
      invoiceData.value = JSON.parse(JSON.stringify(invoiceData.value))
    }

    //----------------------------------------------------------------------------------------------------------------------------

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetinvoiceData)

    return {
      refFormObserver,
      getValidationState,
      onUpdateInvoiceData,
      resetForm,
      invoiceData,
    }
  },
}
</script>

<style>

</style>
